.hero-intro{
    background: linear-gradient(to right, #00220888, #00090257) , url('../assets/aboutbg2.jpg') no-repeat;
    height: 75vh;
    background-size: cover;
    background-position: center ;

}
.hero-intro .hero-content{
     height: calc(75vh - 90px);
     display: flex;
     align-items: center;
}

.hero-content h1{
    font-size: 72px;
    font-family: "DM Sans";
    color: var(--text-color);
    position: relative;
    z-index: 10;
}
.about-body-section{
     padding: 100px 0;
}
.about-body-content{
    display: grid;
    grid-template-columns: 50% auto;
    gap: 80px;
}

.about-body-images{
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 30px
}

.about-body-images .about-body-image-moja:nth-child(1){
    height: 300px;
}
.about-body-images .about-body-image-moja:nth-child(2){
      width: 60%;
      height: 350px;
}
.about-body-image-moja img{
     border-radius: 20px;
}
.about-body-description{
     position: relative;
     display: flex;
     align-items: center;
}
.about-body-description .top-body{
    margin-bottom: 30px;
}
.about-body-description .top-body p{
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 15px;
}
.about-body-description .statements-body{
     position: relative;
     left: -25%;
     display: grid;
     grid-template-columns: repeat(2,1fr);
     width: 130%;
     gap: 30px;
     margin-top: 50px
}
.statements-body .statement-moja{
      text-align: center;
}
.statement-moja h2{
    font-size: 25px;
    margin-bottom: 10px;
    color: var(--dark-green)
}
.statement-moja p{
    font-size: 17px;
    line-height: 32px;
}

.values-section{
     padding-top: 80px;
}
.values-section .values-row{
     display: grid;
     grid-template-columns: repeat(4,1fr);
     gap: 20px;
}
.values-section-content h1{
     font-size: 52px;
     font-family: "DM Sans";
     text-align: center;
     margin-bottom: 30px;
}
.value-moja .value-icon{
     width: 60px;
     height: 60px;
     margin: 20px auto;
}

.value-moja{
     text-align: center;
}
.value-moja h2{
    font-size: 20px;
    font-weight: 500;
}
.value-moja p{
    font-size: 18px;
    margin-top: 10px;
}

.team-section{
     padding: 100px 0 0;
}
.team-section .inner-row{
     width: 85%;
}
.team-intro {
    text-align: center;
}
.team-intro h3{
     font-size: 18px;
     text-transform: uppercase;
     color: var(--dark-green);
}
.team-intro h1{
    font-size: 52px;
    font-family: "DM Sans";
    margin: 20px 0;
}

.team-section-content .team-section-row{
     display: grid;
     grid-template-columns: repeat(4,1fr);
     margin-top: 80px;
     gap: 30px;
}

.team-moja{
     position: relative;
     height: 450px;
     border-radius: 150px;
     overflow: hidden;
}
.team-moja .image-section{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.team-moja .image-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent, transparent, #06280e);
    z-index: 5;
}

.team-moja .team-details{
     position: absolute;
     top:0;
     z-index: 4;
     text-align: center;
     background: linear-gradient(rgba(5, 57, 5, 0.747), rgba(0, 128, 0, 0.75), rgba(0, 128, 0, 0.2));
     width: 100%;
     height: 90%;
     display: flex;
     align-items: center;;
     justify-content: center;
     color: var(--text-color);
     opacity: 0;
     transition: all 0.8s ease-in-out;
}
.team-moja:hover .team-details{
     opacity: 1;
}

.team-details h2{
     font-weight: 400;
     margin-bottom: 10px;
     transform: translateY(20px);
     transition: all 0.7s ease;
}

.team-moja:hover .team-details h2{
    transform: translateY(10px);
}

.team-details p{
     font-weight: 100;
     font-size: 14px;
     transform: translateY(-20px); 
     transition: all 0.7s ease;
     opacity: 0.5;
}

.team-moja:hover .team-details p{
     transform: translateY(10px); 
     opacity: 1;    
}
.team-socials {
     position: absolute;
     bottom: 40px;
     z-index: 5;
     left: 50%;
     transform: translateX(-50%);
}
.team-socials ul{
     display: grid;
     grid-template-columns: repeat(3,1fr);
     gap: 30px;
     transform: translateY(30px);
     opacity: 0;
     transition: all 0.5s ease-in-out;
}
.team-socials ul li a{
     width: 50px;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     color: var(--text-color);
     border: 2px solid var(--text-color);
}
.team-socials ul li:nth-child(2) a{
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
}
.team-socials ul li:nth-child(1),
.team-socials ul li:nth-child(3){
     margin-top: -40px;
     transition: all 0.6s ease-in-out;
}
.team-socials ul li:nth-child(1):hover a,
.team-socials ul li:nth-child(3):hover a{
       background-color: var(--main-color);
       border: 2px solid var(--main-color);
}

.team-moja:hover .team-socials ul{
      transform: translateY(0px);
      opacity: 1;
}

.team-photo{
     height: 90vh;
     margin-top: 100px;
    
     background-size: cover;
     background-position: center;
}

.bulk-images-section{
     background: green;
}
.bulk-images-row{
     display: grid;
     grid-template-columns: repeat(4,1fr);
}

@media(max-width: 1100px){
    .about-body-content{
          grid-template-columns: repeat(1,1fr);
    }
    .about-body-description .statements-body{
          width: 100%;
          left: 0
    }
    .values-section .values-row{
         gap: 20px;
         grid-template-columns: repeat(4,1fr);
    }
    .team-section-content .team-section-row{
         grid-template-columns: repeat(2,1fr);
    }
}

@media(max-width: 768px){
    .about-body-description .statements-body{
         grid-template-columns: repeat(1,1fr);
    }
    .values-section .values-row,
    .bulk-images-row{
        grid-template-columns: repeat(2,1fr);
   }
}

@media(max-width: 500px){
     .values-section .values-row{
          grid-template-columns: repeat(1,1fr);
     }
     .values-section-content h1,
     .hero-content h1,
     .team-intro h1{
          font-size: 42px;
     }
     .team-section-content .team-section-row{
        grid-template-columns: repeat(1,1fr);
   }
}