.hero-intro.expertise{
    background: linear-gradient(to right, #00220888, #00090257) , url('') no-repeat;
    background-size: cover;
    background-position: center 40%;
}

.service-body-section{
     padding-top: 100px;
}
.service-intro h1{
     font-size: 52px;
     text-align: center;
     font-family: "DM Sans";
}

/* Single Service Styles */
.hero-intro.single-service-section{
      background: none;
      position: relative;
}

.hero-image-overlay,
.darken_overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(to right, #00150588, #0009022e);
}

.single-service-body-section{
     padding: 100px 0;

}
.single-service-body-section .single-service-intro{
     display: grid;
     grid-template-columns: 40% auto;
}

.single-service-intro h3{
    font-size:40px;
    font-family: "DM Sans";
    width: 90%;
}
.single-service-intro p{
     font-size: 26px;
     line-height: 46px;
}
.single-section-content h4{
     font-size: 30px;
     font-weight: 300;
     margin: 60px 0  20px;
}
.single-section-content ul {
       width: 95%;
       margin: 0 auto 50px auto;
}
.single-section-content ul li {
     line-height: 35px;
     font-weight: 600;
     font-size: 20px;
}
.services-options ul{
      list-style: disc;
}
.services-options ul li{
      margin-bottom: 20px;
}
.services-options ul li p{
    font-weight: 300;
    font-size: 18px;
}
.products-row{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 30px;
      margin-top: 40px;
}
.products-row .simple-product{
      position: relative;
      height: 400px;
      box-shadow: 0px 0px 20px -5px rgba(216, 216, 216, 0.767);
      border-radius: 20px;
}
.simple-product img{
        border-radius: 20px;
}
.simple-product .simple-product-text{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      transform-origin: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: all 0.7s ease-in-out;
}
.simple-product:hover .simple-product-text{
     background: linear-gradient(rgba(5, 57, 5, 0.747), rgba(5, 57, 5, 0.747));
     transform: scale(1);
}
.simple-product-text h2{
     font-size: 28px;
     color: var(--text-color);
     font-weight: 300;
}

.service-custom-moja{
      display: grid;
      grid-template-columns: repeat(2,1fr);
}
.service-custom-moja:nth-child(odd) .service-custom-image{
      order: 2;
}
.service-custom-description{
      display: flex;
      align-items: center;
      justify-content: center;
}
.service-custom-description .custom-description-inner{
      width: 80%;
}
.service-custom-description h2{
     font-size: 42px;
     font-family: "DM Sans";
     margin-bottom: 20px;
}
.service-custom-description p{
      font-size: 18px;
      line-height: 36px;
}
@media(max-width: 1090px){
     .single-service-body-section .single-service-intro{
           grid-template-columns: repeat(1,1fr);
           text-align: center;
     }
     .single-service-intro h3{
           width: 100%;
     }
     .products-row .simple-product{
           height: 400px;
     }

     .service-custom-moja{
          grid-template-columns: repeat(1,1fr);
          gap: 20px;
    }
    .service-custom-moja:nth-child(odd) .service-custom-description{
          order: 2;
    }

    .service-custom-moja .service-custom-image{
          height: 400px;
          width: 85%;
          margin: auto;
    }
    .service-custom-moja .service-custom-description{
          padding: 40px 0;
    }
}


@media(max-width: 768px){
     .service-intro h1,
     .single-service-section h1,
     .hero-content h1{
        font-size: 35px;
     }
     .single-service-intro p{
          font-size: 18px;
          line-height: 36px;
     }
     .products-row{
           grid-template-columns: repeat(1,1fr);
     }
 
     .simple-product-text h2{
           font-size: 20px;
     }
}


@media(max-width: 480px){
      .products-row .simple-product{
           height: 300px;
      }
      .service-custom-moja .service-custom-image{
            height: 300px;
      }
}