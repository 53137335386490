.hero-intro.products{
    background: linear-gradient(to right, #00220888, #00090257) , url('../assets/products-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center 40%;
}

.products-body-section{
    padding: 100px 0;
}
.products-body-intro {
     display: flex;
     justify-content: space-between;
     align-items: center;
}
.products-body-intro h1{
     font-size: 56px;
     font-family: "DM Sans";
     flex-shrink: 0;
     width: 600px;
}
.products-body-intro p{
    font-size: 18px;
    line-height: 36px;
}

.products-list{
    margin-top: 40px;
}
.products-category:not(.products-category:nth-child(1)){
    margin-top: 50px;
}
.products-category h3{
    font-size: 25px
}
.products-category h3::after{
     content: "";
     width: 80px;
     height: 2px;
     background: var(--main-color);
     display: block;
     margin-top: 10px;
}

.category-row{
    margin: 30px 0;
     display: grid;
     gap: 30px;
     grid-template-columns: repeat(3,1fr);
}

.single-product{
     position: relative;
     border-radius: 15px;
     overflow: hidden;
     box-shadow: 0px 0px 20px -5px rgb(216, 216, 216);
}
.single-product .simple-product-text{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.7s ease-in-out;
}
.single-product:hover .simple-product-text{
   background: linear-gradient(rgba(5, 57, 5, 0.747), rgba(5, 57, 5, 0.747));
   transform: scale(1);
}
.simple-product-text h2{
   font-size: 24px;
   color: var(--text-color);
   font-weight: 300;
   opacity: 0;
   padding: 0 5%;
}
.single-product:hover .simple-product-text h2{
    opacity: 1;
}

@media(max-width: 1090px){
     .category-row{
          grid-template-columns: repeat(2,1fr);
     }
     .products-body-intro{
        text-align: center;
        flex-direction: column;
     }
}

@media(max-width: 768px){
    .products-body-intro h1{
          font-size: 40px;
          width: 100%;
    }
}
@media(max-width: 500px){
     .category-row{
          grid-template-columns: repeat(1,1fr);
     }
}