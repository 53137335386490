:root{
     --swiper-navigation-size: 20px !important;
}
.landing{
    position: relative;
    height: 110vh;
}
.hero-section{
   width: 100%;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.hero-section .media-background{
   width: 100%;
   height: inherit;
   position: inherit;
   top: 0;
   left: 0;
   background:rgb(0, 14, 0);
}

.media-background video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.media-background .overlay{
     position: inherit;
     top: 0;
     left: 0;
     width: 100%;
     height: inherit;
     background-color: rgba(0,0,0,0.3);
}

.hero-section-content{
     position: absolute;
     top: 0;
     width: 100%;
     height: inherit;
     left: 0;
     display: flex;
     justify-content: center;
     align-items: center;
}

.hero-texts h1{
    font-family: "DM Sans";
    font-size: 92px;
    color: var(--text-color);
    text-align: center;
}
.hero-texts p{
      width: 800px;
      margin: 20px auto;
      text-align: center;
      font-size: 20px;
      color: var(--text-color);
      line-height: 36px;
}
.hero-texts a{
    width: 200px;
    margin:20px  auto;
    display: flex;
    height: 55px;
    background: var(--main-color);
    color: var(--text-color);
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.service-variations{
    position: absolute;
    bottom: -13%;
    width: 100%;
}
.service-variations .service-variant-row{
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 2px;
      width: 1200px;
      border-radius: 30px;
      overflow: hidden;
      margin: auto;
}
.service-variant-moja{
     background: white;
     padding: 40px;
}
.service-variant-moja .icon-section{
      width: 60px;
      height: 60px;
      border: 2px solid var(--main-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
}
.service-variant-moja .icon-section img{
     height: 60%;
     width: 60%;
     filter: invert(46%) sepia(29%) saturate(972%) hue-rotate(82deg) brightness(99%) contrast(92%);
}
.service-variant-moja h3{
     font-size: 52px;
     font-family: "DM Sans";
     margin: 5px 0;
     color: var(--main-color);
}
.service-variant-moja p{
     line-height: 25px;
     font-size: 18px;
}
.service-variant-moja a::before{
    width: 65px;
    height: 65px;
    content: "";
    position: absolute;
    top: 50%;
    left: -10%;
    border-radius: 50%;
    transform: translateY(-50%) translateZ(-1px);
    transition: transform 0.7s ease-in-out;
}
.service-variant-moja:nth-child(1) a::before{ 
       background: rgba(223, 222, 153, 0.4); 
}
.service-variant-moja:nth-child(2) a::before{
      background: rgba(255, 177, 189, 0.4);
}
.service-variant-moja:nth-child(3) a::before{
     background: rgba(152, 220, 152, 0.4);
}
.service-variant-moja:hover a::before{
    transform: translateY(-50%) translateZ(-1px) scale(1.15);
}


/* About Section Styles */
.about-section{
    padding: 250px 0px 150px 0px;
    background: var(--main-color);
    position: relative;
    overflow: hidden;
}
 .bg-circle{
     width: 300px;
     height: 300px;
     background: #2b9543d9;
     border-radius: 50%;
     position: absolute;
     left: -5%;
     bottom: -5%;
     box-shadow: 0px 0px 3px -1px rgb(22, 104, 22);
}
.bg-small-circle{
    width: 200px;
    height: 200px;
    background: #2b9543d9;
    border-radius: 50%;
    position: absolute;
    right: 5%;
    top: 5%;
    box-shadow: 0px 0px 3px -1px rgb(22, 104, 22);
}
.about-section-content-row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 90%;
    margin: auto;
    gap: 50px;
    position: relative;
}
.about-section-left-column{
       border-right: 2px solid var(--semi-gold);
     
}
.about-section-left-column p{
     font-size: 18px;
     line-height: 32px;
     color: var(--text-white);
     margin-top: 20px;
     width: 90%;
}
.about-section-left-column h3{
     text-transform: uppercase;
     font-weight: 600;
     color: var(--semi-gold);
     font-size: 24px;
     margin-bottom: 10px;
}
.about-section-left-column h2{
     font-family: "DM Sans";
     font-size: 42px;
     color: var(--text-color);
     width: 90%;
}
.about-section-right-column p{
     font-size: 18px;
     line-height: 32px;
     color: var(--text-white);
     margin-bottom: 20px;
}

.about-section-right-column a{
      width: 200px;
      height: 55px;
      border: 2px solid var(--text-white);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      color: var(--text-white);
      border-radius: 5px;
      gap: 5px;
      transition: all 0.5s ease;
}
.about-section-right-column a span{
    display: flex;
    margin-top: 1px;
}
.about-section-right-column a:hover{
    gap: 8px;
}


/* Capabilities section */
.capability-section{
     padding: 100px 0;
     overflow: hidden;
}

.capability-section .capability-intro{
       display: flex;
       justify-content: space-between;
}
.capability-intro h3{
     font-size: 52px;
     width: 400px;
     font-family: "DM Sans";
     flex-shrink: 0;
}
.capability-intro p{
     font-size: 20px;
     line-height: 32px;
}
.certification-slider{
      width: 900px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;
      flex-wrap: wrap;
      padding-bottom: 40px;
}
.certification-slider  .logo-moja{
      max-width: 230px;
      max-height: 180px;
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
}
.certification-slider .logo-moja.short{
     height: 120px;
     width: max-content;
}
.certification-slider .swiper-wrapper{
      align-items: center;
}
.certification-slider .swiper-slide{
     display: flex;
     display: flex;
     justify-content: center;
}
.swiper-slide .logo-moja img{
      object-fit: contain !important;
}
.swipe-slide .logo-moja.short img{
      width: inherit;
      height: inherit;
}
.capability-section-row{
    margin-top: 70px;
    position: relative;
}
.capability-section-row .capability-section-column{
     display: grid;
     grid-template-columns: 1fr 1fr;
     position: relative;
}
.capability-section-column .small-bubble{
     width: 150px;
     height: 150px;
     background:rgba(255, 177, 189, 0.2);
     position: absolute;
     border-radius: 50%;
     animation: bouncekidogo 3s ease infinite forwards;
}
.capability-section-column .small-bubble.normal{
     left: -5%;
     top: -5%;
}
.capability-section-column .small-bubble.shift{
     right: -5%;
     top: 5%;
     background:rgba(223, 222, 153, 0.2);
}
.capability-section-column .small-bubble.normal.tweak{
     background:rgba(152, 220, 152, 0.2);
     top: 5%;
}
.capability-section-column .capability-description-side{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20% 0;
    position: relative;

}
.capability-description-side .capability-description-inner{
     width: 70%;
}
.capability-description-side h2{
    font-size: 60px;
    font-family: "DM Sans";
    font-weight: 500;
}
.capability-description-side p{
    font-size: 18px;
    line-height: 32px;
}
.capability-description-side a{
     font-size: 18px;
     display: flex;
     margin-top: 30px;
     align-items: center;
     position: relative;
     gap: 0;
     transition:  all 0.5s ease;;
     color: var(--dark-green);
}
.capability-description-side a::after{
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white-green);
    position: absolute;
    left:0;
    transition: all 0.6s ease;
}
.capability-description-side a span:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
}
.capability-description-side a span:nth-child(2){
     margin-left: -5%;
}
.capability-description-side a span .icon{
     opacity: 0;
     transition: all 0.5s ease;
}
.capability-description-side a:hover{
     gap: 35px;
}
.capability-description-side a:hover::after{
    transform: scale(1.3)
}
.capability-description-side a:hover span .icon{
      opacity: 1;
}
.capability-section-column  .capability-image-side{
     position: relative;
     max-height: max-content;
}
/* .capability-image-side img{
      object-fit: contain;
} */

.choose-section{
     background: white;
     padding: 100px 0;
}
.choose-section .inner-row{
     width: 90%;
}
.choose-section .choose-intro{
     text-align: center;
}
.choose-intro h1{
     font-size: 60px;
     font-family: "DM Sans";
     margin: 20px 0;
}

.choose-intro h3{
    text-transform: uppercase;
    color: var(--dark-green);
}

.choose-section-row{
    margin-top: 100px;
}
.choose-section-row{
     display: grid;
     grid-template-columns: 45% auto;
     gap: 30px;
}
.images-side-column{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-around;
     gap: 30px;
     position: relative;
}
.images-side-column .image-moja{
     width: 45%;
     position: relative;
     z-index: 5;
}
.image-moja img{
     border-radius: 20px;
}
.images-side-column .tight-circle{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: green;
      z-index: 2;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      animation: pulsate 4s ease infinite forwards;
}
.images-side-column .choose-image-moja{
     width: 40%;
     position: relative;
     z-index: 5;
     max-height: 150px;
     background: rgb(251, 255, 253);
     box-shadow: 0px 0px 15px -3px rgb(210, 210, 210);
     border-radius: 20px;
     display: flex;
     align-items: center;
     justify-content: center;
     transition: all 0.5s ease;;
}
.choose-image-moja img{
    border-radius: 20px;
    object-fit: contain;
    width: 80%;
    height: 80%;
    transition: all 0.8s ease;
}
.choose-image-moja:hover img{
      transform: scale(1.1);
}
.choose-image-moja:hover{
      transform: scale(1.05);
}
.choose-description-column{
      display: flex;
      align-items: center;
}
.description-side-column{
     display: grid;
     grid-template-columns: repeat(1,1fr);
     height: max-content;
}
.description-side-column .choose-moja{
     position: relative;
     padding-left: 5%;
     margin-bottom: 40px;
     display: flex;
     gap: 5px;
     align-items: center;
}
.choose-moja h2{
    color: var(--dark-green);
    margin-bottom: 10px;
    font-size: 20px;
    position: relative;
    z-index: 3;
}
.choose-moja p{
     line-height: 35px;
     font-size: 18px;
}
.choose-moja span{
    position: relative;
    flex-shrink: 0;
     width: 60px;
     height: 60px;
     color: transparent;
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-bottom: 10px;
     font-family: "DM Sans";
     font-size: 25px;
     transition: all 0.5s ease;
     z-index: 1;
     -webkit-text-stroke: 1px rgba(92, 115, 92, 0.6);
}
.choose-moja:nth-child(1) span{
    background:rgba(223, 222, 153, 0.4)
}
.choose-moja:nth-child(2) span{
    background:rgba(255, 177, 189, 0.4);
}
.choose-moja:nth-child(3) span{
    background:rgba(152, 220, 152, 0.4)
}
.choose-moja:nth-child(4) span{
    background:rgba(255, 206, 177, 0.4);
}
.choose-moja:nth-child(5) span{
    background:rgba(153, 223, 207, 0.4)
}
.choose-moja:nth-child(6) span{
    background:rgba(255, 177, 247, 0.4);
}
.choose-moja:nth-child(7) span{
     background:rgba(152, 220, 152, 0.4)
 }
.choose-moja:hover span{
     transform: scale(1.2);
     font-size: 40px;
     -webkit-text-stroke: 1px rgba(92, 115, 92, 0.2);
}

/* home products section */
.home-products-section{
     padding: 100px 60px 100px 60px;
     background: white;
     position: relative;
}
.home-products-section::after{
      width: 90%;
      content: "";
      height: 1px;
      background-color: rgb(237, 237, 237);
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
}
.products-section-intro{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 100px;
}
.products-section-intro h1{
     font-size: 52px;
     font-family: "DM Sans";
     width: 450px;
     flex-shrink: 0;
}

.products-section-intro p{
      font-size: 18px;
      line-height: 36px;
}

.products-section-row{
      margin-top: 80px;
}
.product-moja{
     display: flex;
     flex-direction: column;
     align-items: center;
}
.product-moja .image-details{
     text-align: center;
     margin: 20px;
}
.image-details h2{
     font-weight: 400;
     font-size: 20px;
}
.product-moja img{
      height: max-content;
      border-radius: 20px;
      object-fit: contain;
}

.swiper-button-next,
.swiper-button-prev{
      color: var(--main-color) !important;
}

.nudge-section{
     background: var(--main-color);
     padding: 100px 0;
     position: relative;
     overflow: hidden;
}

.nudge-section .nudge-section-content{
      width: 80%;
      margin: auto;
      text-align: center;
  
}
.nudge-section-content h1{
     font-size: 52px;
     font-family: "DM Sans";
     color: var(--text-color);
     margin: 20px 0;
     position: relative;
}
.nudge-section-content p{
      font-size: 18px;
      line-height: 32px;
      width: 80%;
      margin: auto;
      color: var(--text-color);
      position: relative;
}
.nudge-section-content a{
     width: 200px;
     height: 55px;
     border: 2px solid var(--text-white);
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 30px auto;
     color: var(--text-white);
     border-radius: 5px;
     gap: 5px;
     transition: all 0.5s ease;
     position: relative;
}
.nudge-section-content a span{
      display: flex;
      margin-top: 2px;
}
.nudge-section-content a:hover{
      gap: 8px;
}

.partners-section{
     padding: 100px 0;
}

.partners-section-content h1{
     font-size: 52px;
     font-family: "DM Sans";
     text-align: center;
}

.clientele-section-row{
     display: flex;
     gap: 50px;
     flex-wrap: wrap;
     justify-content: center;
     margin: 50px auto;
     width: 90%;
}
.clientele-section-row .client-moja{
     width: 150px;
}
.client-moja img{
     object-fit: contain;
}

.testimonial-images{
      display:  grid;
      grid-template-columns: 25% auto 25%;
      gap: 30px;
}

.testimonial-images .testimonial-image-moja:nth-child(2){
     height: 350px;
     margin-top:10%
}
.testimonial-image-moja img{
      border-radius: 20px;
}
.testimonial-wordings{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 40px;
      margin-top: 80px;
}
.wording-moja{
      padding: 30px;
      background: white;
      box-shadow: 0px 0px 8px -4px gainsboro;
      border-radius: 20px;
}
.profile-part .image-section,
.profile-part .image-section img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
}

.wording-moja p{
      line-height: 25px;;
}

.wording-moja .profile-part{
      display: flex;
      align-items: center;
      margin: 20px 0;
      gap: 10px;
}
.wording-moja .profile-part h3{
     font-size: 16px;
}
.wording-moja .profile-part p{
      font-size: 14px;
}


@media(max-width: 1250px){
      .partners-section .inner-row{
           width: 95%;
      }
      .service-variations .service-variant-row{
           width: 100%;
      }
}


@media(max-width: 1200px){
     .about-section-left-column,
     .about-section-left-column h2,
     .about-section-left-column p{
          width: 100%;
     }
     .capability-section-column .capability-description-side{
         padding: 10% 0;
     }
     .capability-description-side .capability-description-inner{
         width: 90%
     }
     
}

@media(max-width: 1090px){
    .hero-texts h1{
         font-size: 72px;
    }
    .hero-texts p{
           width: 80%;
    }
    .service-variations{
         bottom: -23%;
    }

    .service-variant-moja a{
         padding: 40px 0;
         font-size: 18px;
    }
    .about-section-content-row{
         grid-template-columns: repeat(1,1fr);
         gap: 20px;
         text-align: center;
    }
    .about-section-left-column{
          border-right: none;
    }
    .about-section-right-column a{
          margin: 30px auto 0px auto;
    }
    .about-section{
          padding-top: 250px;
    }
    .capability-section .capability-intro{
          flex-direction: column;
          text-align: center;
          gap: 20px;
    }
    .capability-intro h3{
          width: 100%;
    }
      .certification-slider{
           margin: auto;
           width: 80%;
    }
    .capability-section-row .capability-section-column{
          grid-template-columns: 1fr;
    }
    .capability-section-column:nth-child(1) .capability-description-side,
    .capability-section-column:nth-child(3) .capability-description-side,
    .capability-section-column:nth-child(5) .capability-description-side{
          order: 2;
    }
    .capability-section-column .capability-description-side{
          padding: 5% 0
    }
    .choose-section-row{
         grid-template-columns: repeat(1,1fr);
         gap: 50px
    }
    .testimonial-wordings{
        grid-template-columns: repeat(1,1fr);
    }
    .wording-moja{
        width: 400px;
        margin: auto;
    }
 
    .product-moja .image-section{
         display: flex;
         justify-content: center;
    }
    .product-moja img{
           height: 400px;
           object-fit: contain;
           width: max-content;
           margin: auto;
     }
     .products-section-intro{
            flex-direction: column;
            gap:20px;
            text-align: center;
     }
     .products-section-intro h1{
           width: 100%;
     }
}

@media(max-width: 768px){
     .hero-texts h1{
         font-size: 52px;
     }
     
     .service-variations .service-variant-row{
          margin: auto;
          grid-template-columns: repeat(1,1fr);
     }
     .service-variations{
           position: relative;
           bottom: -700px;
     }
     .about-section{
          padding-top: 700px;
    }
     .capability-section .capability-intro{
        grid-template-columns:1fr;
        gap: 30px;
        text-align: center;
     }
     .choose-intro h1,
     .products-section-intro h1,
     .capability-description-side h2,
     .service-variant-moja h3{
          font-size: 40px;
     }
     .images-side-column .tight-circle{
          width: 150px;
          height: 150px;
     }
     .description-side-column{
          grid-template-columns: repeat(1,1fr);
     }
     .description-side-column .choose-moja{
          margin-bottom: 20px;
     }
     .clientele-section-row,
     .nudge-section .nudge-section-content{
           width: 100%;
     }
     .partners-section-content h1,
     .nudge-section-content h1{
           font-size: 43px;
     }
     .testimonial-images .testimonial-image-moja:nth-child(2){
            height: 250px;
     }
}
@media(max-width: 600px){
      .product-moja img{
            height: 300px;
      }
      .testimonial-images{
             grid-template-columns: repeat(1,1fr);
      }
      .testimonial-images .testimonial-image-moja{
              height: 400px;
              margin: 0;
      }
      .testimonial-images .testimonial-image-moja:nth-child(2){
              margin-top: 0;
      }
}
@media(max-width: 480px){
     .wording-moja{
           width: 90%;
     }
     .hero-texts h1,
     .about-section-left-column h2,
     .capability-description-side h2,
     .nudge-section-content h1{
          font-size:35px;
      }
      .about-section-left-column h3,
      .capability-intro h3{
           font-size: 18px;;
      }
      .bg-circle{
           width: 200px;
           height: 200px;
      }
      .bg-small-circle{
           width: 100px;
           height: 100px;
      }
      .home-products-section{
            padding: 100px 10px;
      }
}

@media(max-width: 420px){
     .product-moja img{
          height: 200px;
    }
    .about-section-left-column h2{
         font-size: 30px;
    }
}
@media(max-width: 320px){
     .hero-texts h1,
     .capability-description-side h2{
          font-size: 35px;
      }
      .capability-description-side {
           text-align: center;
           margin-bottom: 30px;
      }
      .capability-description-side a{
          justify-content: center;
      }
      .capability-description-side a::after{
           left: 17%
      }
      .wording-moja p{
           font-size: 13px;
      }
      .wording-moja .profile-part p{
          font-size: 12px;
          line-height: 16px;
      }
      .product-moja img{
          height: 150px;
    }
}
@keyframes bouncekidogo{
     50%{
          transform: translateY(-20px)
     }
     100%{
         transform: translateY(0)
     }
}
@keyframes pulsate {
    0%{
        transform: translate(-50%, -50%) scale(0.95);
    }
      50%{
          transform: translate(-50%, -50%) scale(1);
      }
      100%{
          transform: translate(-50%, -50%) scale(0.95);
      }
}