.hero-intro.contact{
    background: linear-gradient(to right, #00220888, #00090257) , url('../assets/contact.jpg') no-repeat;
    background-size: cover;
    background-position: center 40%;
}

.contactbody-section{
     padding: 100px 0;
}

.quick-details-row{
     width: 90%;
     margin:auto;
     display: grid;
     grid-template-columns:repeat(3,1fr);
     gap: 30px;
}
.quick-details-row .icon-section{
     width: 80px;
     height: 80px;
     margin: 30px auto;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     background: rgba(222, 222, 222, 0.69);
}
.quick-details-row .icon-section span{
    font-size: 30px;
    color: var(--main-color);
    display: flex;
    margin-top: 2px;
}

.quick-details-row .column{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 6px -2px rgba(195, 195, 195, 0.8);
    padding: 30px 0px;
    border-radius: 20px;
}
.quick-details-row .column .column-text{
     width: max-content;
     text-align: center;
     flex-shrink: 0;
}
.column:nth-child(1) .column-text p{
     width: 80%;
     margin: auto;
}
.column-text p{
    font-size: 18px;
    line-height: 36px;
    color: var(--dark-green);
}

.form-section .form-intro{
    width: 800px;
    margin: 80px auto;
    text-align: center;
}
.form-intro h1{
    font-size: 72px;
    font-family: "DM Sans";
}
.form-intro p{
    font-size: 20px;
    line-height: 40px;
    margin: 20px 0;
}

.form-body{
    width: 800px;
    margin: auto;
}
.form-row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 30px;
    margin-bottom: 50px;
}

.form-row .form-column{
     position:relative;
     height: 55px;
     border-bottom: 2px solid var(--dark-green);
}
.form-column label{
     position: absolute;
     top: 50%;
     left: 5%;
     transform: translateY(-50%);
     transition: all 0.6s ease-in-out;
}

.form-column:has(.form-control:focus) label,
.form-column:has(.form-control:not(.form-control:placeholder-shown)) label{
    top: 0;
    font-size: 14px;
    left: 2%;
    transform: translateY(0);
}
.form-column .form-control{
     width: 100%;
     height: 100%;
     background: transparent;
     border: none;
     outline: none;
     padding: 5.6% 5% 0;
     font-family: "Red Hat Text";
     font-size: 16px;
}
.form-column .form-control.interest,
.form-column.interest{
      display: none;
}
.form-column span{
      color: rgb(255, 0, 0);
      font-size: 13px;
      margin-top: 10px;
      display: block;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        display: none;
 }
 .form-message .form-column{
      position: relative;
 }
.form-message textarea{
     border-bottom: 2px solid var(--dark-green) !important;
     width: 100%;
     height: 100px !important;
}
.form-message .form-column label{
     top: 20%;
     left: 2%;
}
.form-message .form-column:has(.form-control:focus) label,
.form-message .form-column:has(.form-control:not(.form-control:placeholder-shown)) label{
     top: 3%;
}
.form-message .form-control{
    padding-left: 3%;
    padding-top: 1%;
}
.form-message textarea{
     resize: none;
     margin-top: 30px;
}
.form-message textarea::-webkit-scrollbar {
    display: none;
  }
  .form-btn{
     text-align: center;
  }
  .form-btn button{
       width: 200px;
       font-family: "Red Hat Text";
       height: 55px;
       font-size: 16px;
       margin: 30px auto;
       background: var(--main-color);
       color: white;
       border-radius: 5px;
       cursor: pointer;
       border: none;
  }
  .form-btn button:hover{
     background: green;
  }

  .location-map iframe{
        width: 100%;
        border: none;
  }
  .form-column span{
     font-size: 13px;
     margin-top: 10px;
     display: block;
     color: red;
}
.server-msg{
      padding: 15px;
      border-radius: 8px;
      background: rgba(182, 248, 206, 0.336);
      font-size: 15px;
}


  @media(max-width: 1090px){
     .quick-details-row{
           grid-template-columns: repeat(1,1fr);
     }
     .form-section .form-intro,
     .form-body{
           width: 100%;
     }
  }

  @media(max-width: 768px){
        .form-intro h1{
             font-size: 50px;
        }
        .form-intro p{
             font-size: 18px;
             line-height: 36px;
        }
        .form-row{
          grid-template-columns: repeat(1,1fr);
        }
  }

  @media(max-width: 480px){
     .quick-details-row .column .column-text{
              width: 80%;
     }
  }