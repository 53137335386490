@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&family=Red+Hat+Text:wght@300;400;500;600&display=swap');

*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

:root{
     --main-color: #2e9747;
     --text-color: #f7f7f7;
     --text-white: #f7f7f7;
     --white-green: rgba(152, 220, 152, 0.4);
     --semi-gold: rgba(255, 231, 170, 0.714);
     --dark-green: rgb(13, 65, 13);
}

body{
    font-family: "Red Hat Text";
    background: #f2f6f3;
}

ul {
   list-style: none;
}
a{
  text-decoration: none;
}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner-row{
    width: 1200px;
    margin: auto;
}
/* Preloader */
.preloader-screen{
     position: absolute;
     width: 100%;
     background: #f2f6f3;
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100vh;
     transition: all 0.7s ease;
}
.preloader-screen.remove{
    transform: translateY(-100%);
}
/* Header Styles */
header{
     height: 90px;
     position: relative;
     z-index: 100;
}
header .inner-row{
      width: 85%;
}
header.scrolled{
     position: fixed;
     top: 0;
     z-index: 100;
     width: 100%;
     background: var(--text-color);
     transform: translateY(-100%);
     transition:  transform 0.7s ease-in-out;
}
header.scrolled.active{
      transform: translate(0);
      box-shadow: 0px 1px 3px -2px rgb(154, 154, 154);
}
header .logo{
  width: 200px;
}
header .logo img,
.sidebar-content .logo img{
  object-fit: contain;
}
header .inner-row{ 
  height: inherit;
}
.header-content{
    width: 100%;
    height: inherit;
    position: relative;
}
.header-content,
.header-content-left,
.header-content-right,
.header-content nav ul{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.header-content nav{
     margin-top: 10px;
}
.header-content nav ul li{
      position: relative;
      cursor: pointer;
      line-height: 45px;
}
.header-content nav .dropdown{
      width: 250px;
      height: auto;
      padding: 20px;
      position: absolute;
      left: 0;
      top: 45px;
      background: #f7f7f7;
      transform-origin: top;
      transform: scaleY(0);
      transition: all 0.5s ease;
}
.sidebar-content nav .dropdown{
     width: 95%;
     height: auto;
     padding: 20px;
     position: relative;
     left: 0;
     top: 0;
     background: #f7f7f7;
     transform-origin: top;
     transform: scaleY(0);
     transition: all 0.5s ease;
     display: none;
}
.header-content nav ul li:hover .dropdown.show,
.sidebar-content nav ul li.with__dropdown:hover .dropdown.show{
      transform: scale(1);
      display: block;
}

.header-content nav .dropdown ul {
       flex-direction: column;
       gap: 10px;
       align-items: flex-start;
}
.header-content nav .dropdown ul li{
     line-height: 30px;
}
.header-content nav .dropdown ul li a{
      color: var(--dark-green);
      font-weight: 400;
}
.header-content nav .dropdown ul li a::after,
.sidebar-content nav .dropdown ul li a::after{
     content: "";
     position: absolute;
     left: 0;
     bottom: -10px;
     width: 0% !important;
     height: 1px;
     transition:  all 0.6s ease;
}
.header-content nav .dropdown ul li a:hover::after,
.sidebar-content nav .dropdown ul li a:hover::after,
.header-content nav .dropdown ul li a.active::after,
.sidebar-content nav .dropdown ul li a.active::after{
     width: 100% !important;
}
.header-content nav ul li a,
.sidebar-content nav ul li a{
    font-weight: 400;
    color: var(--text-color);
    font-size: 17px;
    position: relative;
}
header.scrolled nav ul li a,
.sidebar-content nav ul li a{
     color: #0d0f0d;
}

.header-content nav ul li a::after,
.sidebar-content nav ul li a::after{
     content: "";
     position: absolute;
     left: 0;
     bottom: -10px;
     width: 0%;
     height: 2px;
     background: var(--text-color);
     transition:  all 0.6s ease;
}
header.scrolled nav ul li a::after,
.sidebar-content nav ul li a::after,
.header-content nav .dropdown ul li a::after{
      background: #0d0f0d;
}
.header-content nav ul li a:hover::after,
.header-content nav ul li a.active::after,
.sidebar-content nav ul li a:hover::after,
.sidebar-content nav ul li a.active::after{
       width: 100%;
}
.header-content .number h3{
   color: var(--text-color);
   font-size: 18px;
   font-weight: 400;
}
header.scrolled .number h3{
     color: #0d0f0d;
}
.header-content .header-btn button,
.sidebar-content .header-btn button{
     width: 200px;
     height: 50px;
     display: flex;
     justify-content: center;
     align-items: center;
     background: var(--main-color);
     border-radius: 5px;
     color: var(--text-color);
     border: none;
     cursor: pointer;
     font-size: 16px;
     font-family: "Red Hat Text";
     font-weight: 500;
     gap: 5px;
}
.header-btn button span{
     font-size: 20px;
     display: flex;
}
.menu-btn{
     position: absolute;
     right: 0;
     top: 25px;
     background: var(--main-color);
     color: var(--text-color);
     cursor: pointer;
     border-radius: 6px;
     display: none;
}
.menu-btn span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    font-size: 25px;
}


.sidebar-container{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
}
.sidebar-container.active{
     z-index: 100;
     opacity: 1;
}
.sidebar-container .sidebar-overlay{
     background: #2e9747;
     width: 100%;
     height: 100%;
     left: 0;
     position: absolute;
     top: -100%;
     display: none;
}
.sidebar-overlay.active{
     z-index: 102;
}
.sidebar-container .sidebar-content{
     width: 500px;
     background: white;
     height: 100%;
     padding-top: 20px;
     position: relative;
     transform: translateX(-100%);
     display: none;
}
.sidebar-content.active{
     z-index: 105;
}
.sidebar-content .logo{
     display: flex;
     justify-content:  space-between;
     align-items: center;
     margin-bottom: 20px;
}
.sidebar-container .logo img{
     width: 200px;
}
.sidebar-content .logo span{
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color);
      border-radius: 6px;
      color: white;
      font-size: 24px;
      cursor: pointer;
}

.sidebar-content nav ul li{
     line-height: 45px;
}
.sidebar-content .sidebar-extra{
     margin-top: 20px;
}
.sidebar-content .sidebar-extra .number{
     margin-bottom: 10px;
}



/* Footer Styles */
.footer-section{
      background: linear-gradient(rgba(0, 53, 0, 0.9), rgba(0, 53, 0, 0.9)), url("./assets/footerbg.jpg") no-repeat;
      background-size: cover;;
}
.footer-section .inner-row{
     padding: 80px 0;
}
.footer-section .footer-nudge{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px;
}

.footer-nudge h1{
     font-size: 40px;
     font-family: "DM Sans";
     color: var(--text-color);
     width: 40%;
}

.footer-nudge a{
      width: 80px;
      height: 80px;
      background: var(--text-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;;
      transform-style: preserve-3d;
      margin-left: -10%;
}
.footer-nudge a::after{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      content: "";
      background:rgba(152, 220, 152, 0.4);
      position: absolute;
      z-index: 1;
      animation: pulse 3s 1s ease-in-out forwards infinite;
      transform: translateZ(-1px)
}
.footer-nudge a::before{
     width: 80px;
     height: 80px;
     border-radius: 50%;
     content: "";
     background:rgba(193, 238, 193, 0.4);
     position: absolute;
     z-index: 1;
     animation: pulse 3s ease-in-out forwards infinite;
     transform: translateZ(-1px)
}
.footer-nudge a span{
      font-size: 30px;
      display: flex;
      margin-top: 6px;
      color: var(--main-color);
}
.footer-nudge h3{
     display: flex;
     flex-direction: column;
     font-family: "DM Sans";
     font-size: 32px;
     color: var(--text-color);
}
.footer-nudge h3 span{
      font-family: "Red Hat Text";
      font-size: 20px;
      font-weight: 400;
}

.footer-specifics{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 50px;
      width: 90%;
      margin: auto;
}
.footer-specifics .footer-specific-column{
      padding: 50px;
}
.footer-specific-column h2{
     margin-bottom: 20px;
     font-size: 25px;
     font-family: 500;
     color: var(--text-color);
}
.footer-specific-column ul li{
      line-height: 35px;
      color: var(--text-color);
      gap: 10px;
      display: flex;
      align-items: center;
      transition: all 0.7s ease;
      cursor: pointer;
}
.footer-specific-column ul li span{
     display: flex;
     margin-top: -2px;
}
.footer-specific-column ul li a{
     color: var(--text-white);
}
.footer-specific-column:nth-child(1) ul li:hover a{
     color:rgba(255, 231, 170);
}
.footer-specific-column:nth-child(1) ul li:hover{
      gap: 20px;
}
.footer-specific-column:nth-child(2) ul li{
     line-height: 45px;
}
.footer-specific-column:nth-child(2) ul li span{
      font-size: 24px;
}

.copyright{
     background:rgb(14, 72, 31);
     padding: 20px 50px;
     display: flex;
     justify-content: space-between;
     align-items: center;
}
.copyright p{
     color: rgb(170, 170, 170);
     font-size: 13px;
}
.copyright .social-links ul {
     display: flex; 
     gap: 30px;
}
.copyright .social-links ul li a{
     color: var(--text-color);
}


@media(max-width: 1280px){
  .header-content .number h3{
        font-size: 14px;
  }
  .header-content-right{
         gap: 15px;
  }
  .inner-row{
      width: 90%;
  }
}

@media(max-width: 1100px){
     .sidebar-container .sidebar-overlay,
     .sidebar-container .sidebar-content,
     .menu-btn{
           display: block;
     }
     .header-content nav,
     .header-content .header-content-right{
           display: none;
     }
     .footer-section .footer-nudge{
           flex-direction: column;
           align-items: center;
           gap: 20px;
     }
     .footer-nudge h1{
           width: 100%;
           text-align: center;
     }
     .footer-nudge h3{
           flex-direction: row;
           align-items: center;
           gap: 10px;;
     }
     .footer-nudge a{
           margin-left: 0;
     }
     .footer-specifics{
           width: 100%;
           gap: 10px;
     }
}
@media(max-width: 768px){
      .footer-specifics{
            grid-template-columns: repeat(1,1fr);
            gap: 20px;
      }
      .footer-specifics .footer-specific-column{
           padding: 0px 50px;
      }
      .copyright{
           flex-direction: column;
           gap: 10px;
      }
      .sidebar-container .sidebar-content{
           width: 100%;
      }
}

@media(max-width: 480px){
     .footer-nudge a{
           width: 60px;
           height: 60px;
     }
     .footer-nudge a::after,
     .footer-nudge a::before{
           width: 70px;
           height: 70px;
     }
     .footer-specifics .footer-specific-column{
          padding: 0px;
     }
     .footer-nudge h1{
           font-size: 30px;
     }
     .footer-nudge h3{
           font-size: 18px;
     }
     .footer-nudge h3 span{
           font-size: 14px
     }
     .footer-specific-column h2{
          font-size: 20px;
     }
      .footer-specific-column ul li a,
      .footer-specific-column:nth-child(2) ul li{
           font-size: 15px
      }
      .footer-specific-column:nth-child(2) ul li span{
           font-size: 18px;
      }
      .footer-specific-column:nth-child(2) ul li{
          line-height: 25px;
          margin-bottom: 10px;
     }
     .copyright {
          text-align: center;
     }
}
@keyframes pulse {
      0%{
           transform: translateZ(-1px) scale(0.9);
      }  
      50%{
            transform: translateZ(-1px) scale(1.3);
            opacity: 0.5;
      }   
}